<template>
  <v-theme-provider theme="dark" with-background v-if="!error">
    <div class="bg-background" :class="{'rating-wrapper': !static}">
      <v-card :class="{'bg-surface md:!rounded-0 md:!border-none md:!rounded-tr-[40px] md:!min-w-[335px]': !static}">
        <v-card-text class="pb-3">
          <client-only>
            <v-row>
              <v-col cols="auto">
                <p class="headline-6 pb-[5px]">Średnia ocena</p>
                <p class="items-center flex">
                  <span>{{ rating.average.toFixed(1) }} / {{ bestRating }}</span>
                  <v-icon class="mr-2 ml-1" :icon="mdiStar" color="gold"/>
                  <span>{{ rating.count }} głosów</span>
                </p>
              </v-col>
              <v-divider vertical/>
              <v-col>
                <p class="headline-6">{{ hasSavedRating ? 'Dziękujemy za opinię!' : label }}</p>
                <v-row class="items-center" no-gutters>
                  <v-col>
                    <v-rating
                        class="flex justify-start -ml-1"
                        size="32"
                        color="gold"
                        @update:modelValue="onRatingChange"
                        :readonly="hasSavedRating"
                        v-model="savedRating"
                        :hover="true"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </client-only>
        </v-card-text>
      </v-card>
    </div>
  </v-theme-provider>
</template>
<script lang="ts" setup>
import {type PageRatingDetailsRecord, RatingType, type RatingValue} from '~/models/Rating';
import useRatingApi from '~/service/api/useRatingApi';
import {mdiStar} from '@mdi/js';

const props = withDefaults(defineProps<{
  label: string
  ratingType: RatingType
  metaTitle: string
  metaDescription?: string
  static?: boolean
}>(), {
  static: false,
});
const savedRating = useLocalStorage<Nullable<number>>(`rating-${props.ratingType}`, null);
const {getRating, updateRating,} = useRatingApi();
const {openErrorSnackbar,} = useSnackbar();
const {error, pending, data: rating,} = await getRating(props.ratingType);

const hasSavedRating = computed<boolean>(() => savedRating.value !== null);

const pageRatingDetailsRecord: PageRatingDetailsRecord = {
  [RatingType.CashComparerWidget]: {name: 'Kalkulator kredytu gotówkowego', description: 'Skorzystaj z kalkulatora i sprawdź najlepsze oferty kredytów gotówkowych', url: 'https://lendi.pl/kredyty-gotowkowe',},
  [RatingType.ComparerWidget]: {name: 'Kalkulator kredytu hipotecznego. Oblicz raty kredytu!', description: 'Skorzystaj z kalkulatora rat i sprawdź najlepsze oferty kredytów', url: 'https://lendi.pl/kredyty-hipoteczne',},
  [RatingType.CreditworthinessWidget]: {name: 'Kalkulator zdolności kredytowej', description: 'Skorzystaj z kalkulatora, aby zbadać swoją zdolność kredytową i sprawdzić, na jaką nieruchomość Cię stać', url: 'https://lendi.pl/kalkulator-zdolnosci-kredytowej',},
  [RatingType.RefinanceWidget]: {name: 'Kalkulator zmiany oprocentowania kredytu hipotecznego', description: 'Podaj dane odnośnie swojego kredytu hipotecznego i sprawdź, ile możesz zyskać na zmianie oferty kredytu przed następną podwyżką stóp procentowych', url: 'https://lendi.pl/kalkulator-zmiany-oprocentowania-kredytu-hipotecznego',},
  [RatingType.SafeLoanWidget]: {name: 'Bezpieczny Kredyt 2% - kalkulator programu rządowego', description: 'Sprawdź ile możesz zaoszczędzić dzięki rządowemu programowi Bezpieczny Kredyt 2%', url: 'https://lendi.pl/bezpieczny-kredyt',},
  [RatingType.LoanForStartWidget]: {name: 'Kredyt na Start - kalkulator programu rządowego', description: 'Sprawdź ile możesz zaoszczędzić dzięki rządowemu programowi Kredyt na Start', url: 'https://lendi.pl/kredyt-na-start',},
};


const onRatingChange = async(newValue: RatingValue) => {
  try {
    rating.value.count++;
    await updateRating(props.ratingType, newValue);
    savedRating.value = newValue;
    const {data,} = await getRating(props.ratingType);
    rating.value = data.value;
  } catch (e) {
    rating.value.count--;
    console.error(e);
    openErrorSnackbar('Wystąpił błąd podczas oceniania.');
  }
};

const bestRating: number = 5 as const;

</script>
<style scoped lang="scss">
@import '@/assets/styles/vuetify/base/breakpoints/index';

.rating-wrapper {
  @apply md:fixed md:left-0 z-[101] md:rounded-ss-none md:rounded-b-none md:rounded-tr-[40px];
  bottom: 0;
  @include tablet {
    bottom: unset !important;
  }
}
</style>
